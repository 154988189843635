.stats {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75em;
  background-color: var(--main-dark-color);
  color: var(--main-light-color);
  padding: 0.5em;
  border-radius: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.statBox {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5em;
  text-align: center;
  padding: 0.5em;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .statBox {
    width: 45%;
    height: 100%;
  }
}

.statBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.statBox h2 {
  font-size: 2.75em;
}

.statBox p {
  font-size: 1.5em;
  font-weight: bold;
  flex-grow: 1;
}

.statIcon {
  font-size: 2.25em;
  color: var(--main-light-color);
}
