.contactFormDiv {
  width: 100%;
  background-color: var(--main-dark-color);
  padding: 1rem;
  border-radius: 1em;
  color: var(--main-light-color);
}

.formField {
  background-color: var(--main-light-color);
  color: var(--main-dark-color);
}

@media only screen and (max-width: 576px) {
  .contactFormDiv {
    margin-top: 1em;
  }
}
