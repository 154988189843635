#coverImageDiv {
  position: relative;
}

#quote {
  z-index: 100;
  position: absolute;
  color: white;
  font-size: 24px;
  font-weight: bold;
  left: 10%;
  top: 20vh;
}

#aboutUs {
  width: 100%;
  text-align: justify;
  background-color: var(--main-light-color);
  padding: 1rem;
  border-radius: 1em;
  color: var(--main-dark-color);
}

#aishwarya {
  font-weight: bold;
}

#aishwarya a {
  font-weight: bold;
  text-decoration: none;
  color: rgb(5, 163, 5);
}

#carouselMobile {
  display: none;
}

@media only screen and (max-width: 576px) {
  .mobile-card-container > .row {
    overflow-x: auto;
  }

  .backgroundColorDiv {
    padding-top: 0.75em;
    margin-bottom: 0.5em;
    background-color: #decba430;
    /* background-image: linear-gradient(to right, #decba450, #3e515150); */
    border-radius: 1em;
  }

  #carouselDesktop {
    display: none;
  }

  #carouselMobile {
    display: block;
  }
}
