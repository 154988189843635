/* Container holding the image and the text */
.projectHeader {
  position: relative;
  text-align: center;
  color: white;
  font-size: 1.75rem;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 1rem;
  margin-left: 1.5rem;
}

.projectTitle {
  padding: 1.5rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  background-color: var(--main-light-color);
  color: var(--main-dark-color);
  border-radius: 0.5em 0.5em 0 0;
}

.projectDetails {
  background-color: var(--main-dark-color);
  color: var(--main-light-color);
}

.initials {
  font-weight: 700;
  align-items: center;
}

.tabs {
  padding: 0.25rem;
  font-size: 1rem;
  text-align: center;
}

.projectSummary {
  background-color: var(--main-light-color);
  color: var(--main-dark-color);
  text-align: justify;
  border-radius: 0 0 0.5em 0.5em;
}

.projectSummaryDesc {
  padding: 1rem 4rem;
}
