.slideLabel {
  font-weight: 700;
  font-size: 1.5rem;
  color: whitesmoke;
  text-shadow: tomato;
}

.slideSubLabel {
  font-size: 1rem;
}

.carousel-inner .item {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
  background-position: center;

  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
}

/* Define the zoom-out animation */
@keyframes zoomIn {
  0% {
    transform: scale(1); /* Start with normal */
  }
  100% {
    transform: scale(1.2); /* End with zoomed-in effect */
  }
}

/* Define the zoom-out animation */
@keyframes zoomOut {
  0% {
    transform: scale(1.2); /* Start with zoomed-in effect */
  }
  100% {
    transform: scale(1); /* End with normal size */
  }
}

/* Apply the animation to the active slide */
.carousel-item-zoom-in .animated-image {
  animation: zoomIn 5s ease-in-out infinite; /* Adjust duration and easing as needed */
}

/* Apply the animation to the active slide */
.carousel-item-zoom-out .animated-image {
  animation: zoomOut 5s ease-in-out infinite; /* Adjust duration and easing as needed */
}

.carousel-item {
  transition: none; /* Disable the slide transition */
}

.carousel-inner {
  display: flex;
}

.carousel-inner .carousel-item {
  display: none; /* Hide inactive slides */
}

.carousel-inner .carousel-item.active {
  display: block; /* Show only the active slide */
}
