.youTubeCard {
  /* background-color: var(--main-light-color); */
  color: var(--main-dark-color);
}

@media only screen and (max-width: 576px) {
  .mobile-card-container > .row {
    overflow-x: auto;
  }
}
