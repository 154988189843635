/* Sticky button container */
.contact-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 1000;
  transition: transform 0.3s ease-in-out; /* Smooth animation for popping in */
}

/* Contact button styles */
.contact-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px; /* Width of the button container */
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  text-decoration: none;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.3s ease;
  white-space: nowrap; /* Ensures text doesn't wrap */
  text-align: center;
}

/* Icon styling */
.contact-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}

/* Caption styling */
.contact-caption {
  font-size: 14px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(100%); /* Initially hidden */
  transition: transform 0.3s ease-in-out;
}

/* Hover effects */
.contact-button:hover {
  transform: translateX(0); /* Slide in fully */
  background-color: #0056b3; /* Darker blue on hover */
}

.contact-button:hover .contact-caption {
  transform: translateY(0); /* Slide in text from bottom to top */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .contact-button {
    width: 120px;
  }
  .contact-icon {
    width: 18px;
    height: 18px;
  }
  .contact-caption {
    font-size: 12px;
  }
}
