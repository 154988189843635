.teamMemberCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 1em;
  min-width: 15%;
  max-height: 100vh;
  background-color: var(--main-light-color);
  transition: 0.25s;
  color: var(--main-dark-color);
  text-align: center;
}

.teamMemberCard > img {
  object-fit: cover;
  width: 100%;
  height: 250px;
  /* max-height: 20%; */
  border-radius: 1em;
}

.name {
  font-size: 1.15rem;
  font-weight: 700;
}

#teamMemberImage {
  font-size: 0.95rem;
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 576px) {
  .teamMemberCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;
    margin: 0.5em 0;
    border-radius: 1em;
    min-width: 15%;
    max-height: 100vh;
    background-color: #ffffff85;
    transition: 0.25s;
    color: var(--main-dark-color);
    text-align: center;
  }
}
