.projectCardDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 1em;
  min-width: 15%;
  max-height: 75vh;
  background-color: var(--main-light-color);
  transition: 0.25s;
  color: var(--main-dark-color);
  text-align: center;
}

.projectCardDiv > img {
  object-fit: fill;
  width: 100%;
  height: 400px;
  /* max-height: 20%; */
  border-radius: 1em;
}

.title {
  font-size: 1.15rem;
  font-weight: 700;
}

#projectImage {
  font-size: 0.95rem;
  margin-bottom: 0.5em;
}

@media (min-width: 767px) {
  .projectCardDiv:hover {
    translate: 0 -5px;
    cursor: pointer;
    -webkit-box-shadow: 2px 5px 6px -2px rgba(23, 61, 72, 1);
    -moz-box-shadow: 2px 5px 6px -2px rgba(23, 61, 72, 1);
    box-shadow: 2px 5px 6px -2px rgba(23, 61, 72, 1);
  }
}

@media only screen and (max-width: 576px) {
  .projectCardDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;
    margin: 0.5em 0;
    border-radius: 1em;
    min-width: 15%;
    max-height: 75vh;
    background-color: #ffffff85;
    transition: 0.25s;
    color: var(--main-dark-color);
    text-align: center;
  }
}
