.footer {
  background-color: var(--main-light-color);
  color: var(--main-dark-color);
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 50px;
  margin-top: 25px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  color: var(--main-dark-color);
  display: inline-block;
  vertical-align: top;
}

/* footer left*/

.footer .footer-left {
  color: var(--main-dark-color);
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 20px;
  color: var(--main-dark-color);
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: var(--main-dark-color);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: var(--main-dark-color);
  border-radius: 2px;
  font-size: 20px;
  color: var(--main-light-color);
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: var(--main-dark-color);
  color: var(--main-light-color);
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: var(--main-dark-color);
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: var(--main-dark-color);
  text-decoration: none;
}

/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: var(--main-dark-color);
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: var(--main-dark-color);
}

.footer .menu {
  color: var(--main-dark-color);
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: var(--main-dark-color);
}

.footer .name {
  color: var(--main-dark-color);
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

#developer {
  margin-top: 1em;
  font-family: consolas;
  font-size: small;
  background: #1b1b1b;
  padding: 0.5em 1.25em;
  color: whitesmoke;
  overflow-x: auto;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 0.65em;
  border: none;
  width: fit-content;
  border-radius: 1em;
  text-align: center;
}

#developer a {
  text-decoration: none;
  /* text-decoration: whitesmoke; */
  color: greenyellow;
}

#footer-left-title {
  font-weight: bolder;
  font-size: 1.25em;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }
  .footer .footer-center i {
    margin-left: 0;
  }

  #developer {
    margin: 0 auto;
    margin-top: 1em;
    font-family: consolas;
    font-size: small;
    background: #1b1b1b;
    padding: 0.5em 1.25em;
    color: whitesmoke;
    overflow-x: auto;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 0.65em;
    border: none;
    width: fit-content;
    border-radius: 1em;
    text-align: center;
  }

  #footer-left-title {
    text-align: center;
  }
}
