span.circle {
  height: 1em;
  width: 1em;
  margin-right: 1.25em;
  border-radius: 100%;
  border: 1px solid #5e5d5d;
  background: #dcdbdb;
  cursor: pointer;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
#first {
  height: 1em;
  width: 1em;
  margin-right: 1.25em;
  border-radius: 100%;
  border: 1px solid #878787;
  background: #878787;
  cursor: pointer;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}

.test div {
  display: table-cell;
  width: auto;
  text-align: center;
}
.test {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.25em;
  margin-bottom: 0.75em;
}

@media (min-width: 767px) {
  .test {
    display: none;
  }
}
